/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Image, Quote, QuoteAuthor} from "smooth-doc/components";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    code: "code",
    em: "em",
    h2: "h2",
    h3: "h3",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "backend-application-with-jpa",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#backend-application-with-jpa",
    "aria-label": "backend application with jpa permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Backend Application with JPA"), "\n", React.createElement(_components.p, null, "Modeling your JPA entities following DDD Aggregates principles."), "\n", React.createElement(_components.p, null, "ZenWave favors modeling JPA relationships following DDD Aggregate principles."), "\n", React.createElement(_components.p, null, "So in this DDD context, preferred relationships are: ", React.createElement(_components.code, null, "OneToMany"), " and ", React.createElement(_components.code, null, "OneToOne"), " from the aggregate root to each dependent, but you can still use ", React.createElement(_components.code, null, "ManyToOne"), " and ", React.createElement(_components.code, null, "ManyToMany"), " to suit your specific needs."), "\n", React.createElement(_components.p, null, "ZenWave also supports describing relationships between aggregates using ", React.createElement(_components.code, null, "ManyToXXX"), " where an aggregate root references another aggregate root, yet those relationships will be mapped by ", React.createElement(_components.em, null, "by-id"), " and providing a read-only and lazy-loading reference to the entity. This improves expressiveness and still following DDD principles. Checkout ", React.createElement(_components.a, {
    href: "#relationships-between-aggregates"
  }, "Relationships Between Aggregates"), " section for more details."), "\n", React.createElement(_components.h2, {
    id: "mapping-relationships",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#mapping-relationships",
    "aria-label": "mapping relationships permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Mapping Relationships"), "\n", React.createElement(_components.h3, {
    id: "onetomany",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#onetomany",
    "aria-label": "onetomany permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "OneToMany"), "\n", React.createElement(_components.p, null, "Let’s start with a classic OneToMany relationship: an Owner and a Car. An owner can have many cars, and a car can have only one owner."), "\n", React.createElement(_components.p, null, "You can map it bidirectionally with the following code:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "@aggregate\r\nentity Owner { }\r\n\r\nentity Car { }\r\n\r\nrelationship OneToMany {\r\n  Owner{cars} to Car{owner}\r\n}\n")), "\n", React.createElement(_components.p, null, "Or unidirectional as:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "relationship OneToMany {\r\n  Owner{cars} to Car\r\n}\n")), "\n", React.createElement(_components.h3, {
    id: "onetoone",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#onetoone",
    "aria-label": "onetoone permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "OneToOne"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "@aggregate\r\nentity Customer { }\r\n\r\nentity Address { }\r\n\r\nrelationship OneToOne {\r\n    Customer{address} to Address{customer}\r\n}\n")), "\n", React.createElement(_components.p, null, "Will result in the following JPA mapping:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-java"
  }, "@Entity\r\n@Table(name = \"customer\")\r\npublic class Customer implements Serializable {\r\n    //...\r\n\t@OneToOne(cascade = CascadeType.ALL, orphanRemoval = true)\r\n\t@JoinColumn(unique = true)\r\n\tprivate Address address;\r\n}\r\n\r\n@Entity\r\n@Table(name = \"address\")\r\npublic class Address implements Serializable {\r\n    //...\r\n\t@OneToOne(mappedBy = \"address\")\r\n\tprivate Customer customer;\r\n}\n")), "\n", React.createElement(_components.h3, {
    id: "onetoone-between-aggregates",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#onetoone-between-aggregates",
    "aria-label": "onetoone between aggregates permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "OneToOne between Aggregates"), "\n", React.createElement(_components.p, null, "If your use a ", React.createElement(_components.code, null, "OneToOne"), " relationship between aggregates, ZenWave will map it by id, with a read-only reference to the entity."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "@aggregate\r\nentity Customer { }\r\n@aggregate\r\nentity Address { }\r\n\r\nrelationship OneToOne {\r\n    Customer{address} to Address{customer}\r\n}\n")), "\n", React.createElement(_components.p, null, "Will result in the following JPA mapping:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-java"
  }, "@Entity\r\n@Table(name = \"customer\")\r\npublic class Customer implements Serializable {\r\n    //...\r\n\t@Column(name = \"address_id\")\r\n\tprivate Long addressId;\r\n\r\n\t@OneToOne(cascade = CascadeType.ALL, orphanRemoval = true)\r\n\t@JoinColumn(unique = true)\r\n\tprivate Address address;\r\n}\r\n\r\n@Entity\r\n@Table(name = \"address\")\r\npublic class Address implements Serializable {\r\n    //...\r\n\t@OneToOne(mappedBy = \"address\", cascade = CascadeType.ALL, orphanRemoval = true)\r\n\tprivate Customer customer;\r\n}\n")), "\n", React.createElement(_components.p, null, "See ", React.createElement(_components.a, {
    href: "#relationships-between-aggregates"
  }, "Relationships Between Aggregates"), " section for more details."), "\n", React.createElement(_components.h3, {
    id: "mapsid-with-onetoone",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#mapsid-with-onetoone",
    "aria-label": "mapsid with onetoone permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "@MapsId with OneToOne"), "\n", React.createElement(_components.p, null, "You can use JPA Derived Identifiers (", React.createElement(_components.code, null, "@MapsId"), ") for one-to-one relationship"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "@aggregate\r\nentity Customer { }\r\n\r\nentity Address { }\r\n\r\nrelationship OneToOne {\r\n    Customer{address required} to @Id Address{customer}\r\n}\n")), "\n", React.createElement(_components.p, null, "Will result in the following JPA mapping:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-java"
  }, "public class Customer implements Serializable {\r\n    @OneToOne(fetch = FetchType.LAZY, cascade = CascadeType.ALL, orphanRemoval = true)\r\n\t@NotNull\r\n\t@MapsId\r\n\t@JoinColumn(name = \"id\")\r\n\tprivate Address address;\r\n}\r\n\r\npublic class Address implements Serializable {\r\n\t@OneToOne(mappedBy = \"address\", fetch = FetchType.LAZY, cascade = CascadeType.ALL, orphanRemoval = true)\r\n\tprivate Customer customer;\r\n}\n")), "\n", React.createElement(_components.h3, {
    id: "manytoone",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#manytoone",
    "aria-label": "manytoone permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ManyToOne"), "\n", React.createElement(_components.p, null, "If your use an unidirectional ", React.createElement(_components.code, null, "ManyToOne"), " relationship from an aggregate to another aggregate root, ZenWave will map it by id, with a read-only reference to the entity."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "@aggregate\r\nentity Customer { }\r\n@aggregate\r\nentity Address { }\r\n\r\nrelationship ManyToOne {\r\n    Address{customer} to Customer\r\n}\n")), "\n", React.createElement(_components.p, null, "Will result in the following JPA mapping:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-java"
  }, "@Entity\r\n@Table(name = \"address\")\r\npublic class Address implements Serializable {\r\n    //...\r\n\t@Column(name = \"customer_id\")\r\n\tprivate Long customerId;\r\n\r\n\t@ManyToOne(fetch = FetchType.LAZY)\r\n\t@JoinColumn(name = \"customer_id\", updatable = false, insertable = false)\r\n\tprivate Customer customer;\r\n\r\n}\n")), "\n", React.createElement(_components.p, null, "See ", React.createElement(_components.a, {
    href: "#relationships-between-aggregates"
  }, "Relationships Between Aggregates"), " section for more details."), "\n", React.createElement(_components.h3, {
    id: "manytomany",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#manytomany",
    "aria-label": "manytomany permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ManyToMany"), "\n", React.createElement(_components.p, null, "ManyToMany relationships are not very common in DDD, but you can still use ZenWave ZDL to model them and get your JPA entities generated."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "entity Driver {}\r\nentity Car {}\r\n\r\nrelationship ManyToMany {\r\n  Car{driver} to Driver{car}\r\n}\n")), "\n", React.createElement(_components.h2, {
    id: "relationships-between-aggregates",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#relationships-between-aggregates",
    "aria-label": "relationships between aggregates permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Relationships Between Aggregates"), "\n", React.createElement(_components.p, null, "In Domain-Driven Design (DDD), because an aggregate is a cluster of domain objects that should be treated as a single unit. Relationships between aggregates are be mapped by their IDs rather than direct object references."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "@aggregate\r\nentity Customer {\r\n}\r\n\r\n@aggregate\r\nentity Address {\r\n  customerId Long\r\n}\n")), "\n", React.createElement(_components.p, null, "This helps to maintain consistency and integrity of the data within the aggregate but is way less expressive because in terms of modelling."), "\n", React.createElement(_components.p, null, "ZenWave SDK allows you to define ", React.createElement(_components.code, null, "@ManyToNNN"), " relationships between aggregates, which are then mapped by their IDs this an extra a read-only and lazy-loading reference to the entity."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "@aggregate\r\nentity Customer { }\r\n\r\n@aggregate\r\nentity Address { }\r\n\r\nrelationship ManyToOne {\r\n    // ManyToNNN relationships between @aggregates are mapped by id, with a read-only reference to the entity\r\n    Address{customer} to Customer\r\n}\n")), "\n", React.createElement(_components.p, null, "The above ZDL model will generate the following JPA mapping:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-java"
  }, "@Table(name = \"address\")\r\npublic class Address implements Serializable {\r\n\r\n    // ...\r\n\r\n    @Column(name = \"customer_id\") // Mapped by id\r\n    private Long customerId;\r\n\r\n    @ManyToOne(fetch = FetchType.LAZY) // Read-only reference to the entity\r\n    @JoinColumn(name = \"customer_id\", updatable = false, insertable = false)\r\n    private Customer customer;\r\n}\n")), "\n", React.createElement(_components.p, null, "And an ", React.createElement(_components.code, null, "IntegrationTest"), " to validate your Aggregates relationships are working as expected:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-java"
  }, "public class AddressRepositoryIntegrationTest extends BaseRepositoryIntegrationTest {\r\n\r\n\t@Test\r\n\tpublic void saveTest() {\r\n\t\tAddress address = new Address();\r\n\t\taddress.setStreet(\"\");\r\n\t\taddress.setCity(\"\");\r\n\t\taddress.setState(\"\");\r\n\t\taddress.setZip(\"\");\r\n\t\taddress.setType(AddressType.values()[0]);\r\n\r\n\t\t// ManyToOne customer. owner: true\r\n\t\tvar customerId = 1L;\r\n\t\taddress.setCustomerId(customerId); // using id to write relationship\r\n\r\n\t\t// Persist aggregate root\r\n\t\tvar created = addressRepository.save(address);\r\n\r\n\t\tentityManager.refresh(created); // reloading to get relationships persisted by id\r\n\t\tAssertions.assertTrue(created.getId() != null);\r\n\t\tAssertions.assertTrue(created.getVersion() != null);\r\n\r\n        // loading and validating relationship\r\n\t\tAssertions.assertTrue(address.getCustomer().getId() == customerId);\r\n\t}\r\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
